<template>
  <div>
    <v-tabs
      v-model="tab"
      height="25px"
      class="mt-2">
      <v-tab
        v-for="(data, i) in printData"
        :key="i"
        v-text="data.nama"/>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(itemx, y) in printData"
          :key="y"
        >
          <v-container v-for="(item, x) in itemx.print" :key="x">
            <v-row class="align-center grey lighten-3 mb-3">
              <v-col cols="3" sm="2">
                <v-text-field
                  type="number"
                  name="jumlah"
                  label="JML"
                  class="text-center"
                  outlined
                  v-model="item.jumlah"
                  dense
                  flat
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="8" class="py-2 text-uppercase">
                <div>{{item.nama}}</div>
                <div class="text-normal">{{item.label}}</div>
              </v-col>
              <v-col cols="3" sm="2" class="justify-end">
                <v-switch hide-details class="mt-0" color="primary" name="enable" dense inset v-model="item.enable"/>
              </v-col>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions class="text-body-1 px-3">
                    <div class="col-10 pa-0">Preview</div>
                    <v-avatar
                      size="20"
                      tile
                      class="col-2 pa-0 justify-end"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-avatar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="pa-2 mb-2" style="background:#fff;margin: auto;border:1px solid #000;border-radius:5px;width:fit-content">
                      <iframe frameBorder="0" class="embed-responsive-item" :src="item.urlToPrint" scrolling="no" :style="`
                      width: ${item.width}mm;
                      height: ${item.height}mm;`"/>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "PrintData",
  props: {
    pasien: [String, Number],
    data: Object,
    label: Array,
  },
  data() {
    return {
      tab: 0,
      url: this.$store.state.settings.server_url || "http://localhost:3030",
      printUrl: (this.$store.state.settings.print_server ? "http://localhost:3030" : this.$store.state.settings.server_url) || "http://localhost:3030",
    };
  },
  created() {
    
  },
  computed: {
    printData() {
      let label = this.label || this.$store.state.settings.print_data;
      let printData = label.map((item, index) => {
        item.print = [];
        item.label.forEach((label) => {
          if(label.content.type === 'resep') {
            this.obat.forEach(obat => {
              if(obat.tipe === label.content.etiket) {
                item.print.push({
                  enable: true,
                  jumlah: label.content.jumlah,
                  printer: label.content.printer,
                  nama: obat.obat,
                  label: `${label.name} (${label.content.printer})`,
                  urlToPrint: this.url + '/label/' + label._id + '/' + this.pasien + '?resep_id=' + this.data.id + '&obat_id=' + obat.id + '&waktu=' + obat.waktu + '&udd=' + this.$store.state.settings.resep?.udd_by + '&gizi=' + this.$store.state.settings.gizi + '&ke=' + obat.ke + '&jam=' + obat.jam,
                  landscape: label.content.printMode === 'landscape' ? true : false,
                  width: label.content.width,
                  height: label.content.height,
                  paper: JSON.stringify({height:label.content.height*1000, width: label.content.width*1000}),
                })
              }
            })
          } else {
            item.print.push({
              enable: true,
              jumlah: label.content.jumlah,
              printer: label.content.printer,
              nama: null,
              label: `${label.name} (${label.content.printer})`,
              urlToPrint: this.url + '/label/' + label._id + '/' + this.pasien,
              landscape: label.content.printMode === 'landscape' ? true : false,
              width: label.content.width,
              height: label.content.height,
              paper: JSON.stringify({height:label.content.height*1000, width: label.content.width*1000}),
            })
          }
        })
        
        return item
      })
      
      return printData;
    },
    obat() {
      let obat = [];

      this.data.obat && this.data.obat.forEach((item) => {
        if (!item.tipe.includes('udd') && !(this.$store.state.settings.gizi && item.tipe === 'racikan_biru')) obat.push(item);
        if(this.$store.state.settings.gizi && item.tipe === 'racikan_biru') {
          let one = {...item}
          one.jam = item.waktu1
          one.ke = item.ke1
          one.obat = 'DIIT CAIR KE ' + one.ke
          if(one.jam) obat.push(one)

          let two = {...item}
          two.jam = item.waktu2
          two.ke = item.ke2
          two.obat = 'DIIT CAIR KE ' + two.ke
          if(two.jam) obat.push(two)
          // console.log('ee');
        }
      });
      const groupBy = (x,f)=>x.reduce((a,b)=>((a[f(b)]||=[]).push(b),a),{});
      // obat.push(this.data.obat && this.data.obat.find((item) => item.tipe === "udd"));
      // obat.push(this.data.obat && this.data.obat.find((item) => item.tipe === "udd_biru"));
      // console.log(groupBy(this.data.obat.filter(z => z.tipe === 'udd'), v => v.waktu));
      if(this.data.obat?.length) {
        let xxx = this.data.obat.filter(z => z.tipe === 'udd')
        let waktu = xxx.map(item => item.waktu_udd);
        let obatx = []
        if(waktu) {
          let aaa = waktu.map((item, i) => {
          	let zzz = item && item.map((x,z) => {
              return x && x.dosis && x.dosis !== '0' && x
            })
            return zzz && zzz.filter(x => x)
          })

          obatx = aaa && aaa.flatMap((x,z) => {
          	let b = x && x.flatMap((c,d) => {
            	let obat = {}
              Object.assign(obat, xxx[z])
              obat.obat = 'UDD MINUM ' + c.waktu
              obat.waktu = c.waktu
              obat.qty = c.dosis
              return obat
            })
            return b && b.filter(x => x.waktu)
          })
        }
        let udd = {};
        if(this.$store.state.settings.resep?.udd_by === 'default') {
          obat.push(xxx[0]);
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu') {
          udd = groupBy(xxx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu_split') {
          udd = groupBy(obatx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        }
      }
      if(this.data.obat?.length) {
        let xxx = this.data.obat.filter(z => z.tipe === 'udd_biru')
        let waktu = xxx.map(item => item.waktu_udd);
        let obatx = []
        if(waktu) {
          let aaa = waktu.map((item, i) => {
          	let zzz = item && item.map((x,z) => {
              return x && x.dosis && x.dosis !== '0' && x
            })
            return zzz && zzz.filter(x => x)
          })

          obatx = aaa && aaa.flatMap((x,z) => {
          	let b = x && x.flatMap((c,d) => {
            	let obat = {}
              Object.assign(obat, xxx[z])
              obat.obat = 'UDD BIRU / INJEKSI ' + c.waktu
              obat.waktu = c.waktu
              obat.qty = c.dosis
              return obat
            })
            return b && b.filter(x => x.waktu)
          })
        }
        let udd = {};
        if(this.$store.state.settings.resep?.udd_by === 'default') {
          obat.push(xxx[0]);
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu') {
          udd = groupBy(xxx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        } else if(this.$store.state.settings.resep?.udd_by === 'waktu_split') {
          udd = groupBy(obatx, v => v && v.waktu)
          udd && Object.values(udd).forEach(item => {
            obat.push(item[0]);
          })
        }
      }
      console.log(obat.filter(item => item));
      
      return obat.filter(item => item)
    }
  },
  methods: {
    changeAll(enable) {
      this.printData[this.tab].print.map((item) => item.enable = enable)
    },
    doPrint() {
      // console.log(this.printData[this.tab]);
      let url = this.printUrl + "/print";
      let data = this.printData[this.tab].print;
      let jobs = []
      data.forEach((item) => {
        if(item.enable) {
          jobs.push({
            url: item.urlToPrint,
            printer: item.printer,
            settings: {
              copies: item.jumlah,
              landscape: item.landscape,
              paper: item.paper
            }
          });
          
          this.trackEvent(
            this.$store.state.settings.app_name,
            "print",
            `${this.$store.state.settings.unit} - ${item.label}`,
            item.jumlah
          );
        }
      })
      
      console.log(jobs);
      if (jobs.length) {
        this.axios
          .post(url, { jobs: jobs, portable: this.$store.state.settings.print_portable || false })
          .then(r => {
            // console.log(r.data);
            if(this.$store.state.settings.print_portable) {
              window.location.href = this.url + r.data;
            }
          })
          .catch(err => {
            console.log(err.message);
          });
      }
    }
  }
};
</script>
